import React from 'react'
function NotFound () {
  return (
    <div className='contentBlock'>
      <h1>404...</h1>
      <p>How'd you get here? Mind your own business.</p>
    </div>
  )
}
export default NotFound
